import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField, InputLabel } from '@mui/material';

const PasswordResetModal = ({ show, handleClose }) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const handleResetPassword = () => {
        // Reset error and success message
        setError("");
        setSuccessMsg("");
        console.log('Reseted error msg', email); // Before sending the request
    
        // Send request to backend to reset password
        // let url = "https://alarms.filter.eu/php/reset_password.php";
        // let headers = {
        //     "Accept": "application/json",
        //     "Content-type": "application/json"
        // };
        // let Data = {
        //     email: email,
        // };
        // fetch(url, {
        //     method: "POST",
        //     headers: headers,
        //     body: JSON.stringify(Data)
        // })
        // Send request to backend to reset password
        fetch('http://localhost/alarms/password_reset.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Received response:', data); // After receiving response
            if (data.error) {
                setError(data.error);
                console.log('Stack in error ', email); // Inside error condition
            } else if (data.success) {
                console.log('Success response:', data); // Inside success condition
                console.log(email);
                setSuccessMsg(data.success);
            }
        })
        .catch((error) => {
            console.error('Error occurred:', error); // Inside catch block
            setError("An error occurred while processing your request for email: " + email);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Password Reset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <div className="alert alert-danger">{error}</div>}
                {successMsg && <div className="alert alert-success">{successMsg}</div>}
                <form>
                    <div className="mb-3">
                        <InputLabel htmlFor="email" className="form-label">Enter your email:</InputLabel>
                        <TextField type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary resetPassCloseButton" onClick={handleClose}>Close</Button>
                <Button variant="primary resetPassButton" onClick={handleResetPassword}>Reset Password</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PasswordResetModal;