import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

import ChangePasswordModal from "./ChangePasswordModal";
import AdminConsoleModal from "./AdminConsoleModal";
import Navigation from "./Navigation";
import '../css/dashboard.css'

const DashboardLvDk = () => {

  // Function to map country codes to their corresponding full names and flag paths
  const mapCountryCodeToInfo = (countryCode) => {
    switch (countryCode) {
      case 'lv':
        return { name: 'Latvia', flag: 'https://alarms.filter.eu/img/latvia-flag.png' };
      case 'ee':
        return { name: 'Estonia', flag: 'https://alarms.filter.eu/img/estonia-flag.png' };
      // Add more cases for other country codes as needed
      default:
        return { name: countryCode, flag: null }; // Return the original code if not found
    }
  };

  const [user, setUser] = useState('');
  const [names, setNames] = useState([]);
  const [employeeShiftData, setEmployeeShiftData] = useState([]);
  const [onlineTechnician, setOnlineTechnician] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showAdminConsoleModal, setShowAdminConsoleModal] = useState(false);
  const storedUser = localStorage.getItem('user');

  const openAdminConsoleModal = () => {
    setShowAdminConsoleModal(true);
  };

  const handleCloseAdminConsoleModal = () => {
    setShowAdminConsoleModal(false);
  };

  useEffect(() => {
    setUser(storedUser);
    fetchNames();
    fetchShiftData();
    fetchOnlineTechnician();
    
    const fetchIsPasswordChanged = async () => {
      try {
        const response = await fetch(`https://alarms.filter.eu/php/get_is_password_changed.php?username=${storedUser}`);
        const data = await response.json();
        
        if (response.ok) {
          // Check if is_password_changed is 'no' in the response
          const isPasswordChangedNo = data[0].is_password_changed === 'no';
          if (isPasswordChangedNo) {
            setShowPasswordModal(true);
          }
        } else {
          // If response is not OK (e.g., HTTP error status), throw an error
          throw new Error(data.error || 'Unknown error occurred.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Display the error message in your frontend (e.g., in a toast, alert, or modal)
      }
    };
    
    fetchIsPasswordChanged();

  }, [storedUser]);

  const handleNameSelection = (event) => {
    setSelectedName(event.target.value);
  };

  const handleClosePasswordModal = () => {
    // Close the modal
    setShowPasswordModal(false);
  };

  const handleStartShift = () => {
    if (selectedName) {
      fetch('https://alarms.filter.eu/php/send_ee_shift_name.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: selectedName }),
      })
        .then(response => response.json())
        .then(data => {
          // Handle response from the backend
          console.log(data);
          window.location.reload()
        })
        .catch(error => {
          console.error('Error starting shift:', error);
        });
    } else {
      alert('Please select a name.');
    }
  };

  const handleStopShift = () => {
    if (selectedName) {
      fetch('https://alarms.filter.eu/php/stop_ee_shift_name.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: selectedName }),
      })
        .then(response => response.json())
        .then(data => {
          // Handle response from the backend
          console.log(data);
          window.location.reload()
        })
        .catch(error => {
          console.error('Error starting shift:', error);
        });
    } else {
      alert('Please select a name.');
    }
  };

  const fetchShiftData = () => {
    fetch('https://alarms.filter.eu/php/get_employee_lv_dk_shift_history.php')
      .then(response => response.json())
      .then(data => {
        console.log('Data from fetch:', data); // Log the data
        setEmployeeShiftData(data);
      })
      .catch(error => console.error('Error fetching employee data:', error));
  };

  const fetchNames = () => {
    fetch('https://alarms.filter.eu/php/get_employee_lv_dk_names.php')
      .then(response => response.json())
      .then(data => setNames(data))
      .catch(error => console.error('Error fetching names:', error));
  };

  const fetchOnlineTechnician = () => {
    fetch('https://alarms.filter.eu/php/get_online_technician.php')
      .then(response => response.json())
      .then(data => setOnlineTechnician(data))
      .catch(error => console.error('Error fetching online technician user:', error));
  };

  return (
    <>
      <Navigation storedUser={storedUser} openAdminConsoleModal={openAdminConsoleModal} />
      <Container style={{ marginTop: '50px' }}>
        <Row>
          <Col style={{ color: '#00A3E0' }}>
            <center><h1>Welcome <b>{user}</b> to Estonian technical alarms dashboard !</h1></center>
          </Col>
        </Row>
        <Row style={{ marginTop: '50px' }}>
          <Col md={5} className="mx-auto" style={{ paddingTop: '15px', border: '1px solid #00A3E0', borderRadius: '20px', backgroundColor: '#DDF2FA', color: '#00A3E0' }}>
            <div className="text-center mb-4">
              <h3>Start from here:</h3>
            </div>
            <Row>
              <Col md={6}>
                <form>
                  {names.map((name) => (
                    <div key={name} className="mb-3">
                      <input type="radio" id={name} name="employee" value={name} checked={selectedName === name} onChange={handleNameSelection} />
                      <label htmlFor={name} style={{ marginLeft: '5px' }}>{name}</label>
                    </div>
                  ))}
                </form>
              </Col>
              <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
                <div className="text-center mb-4">

                </div>
                <ButtonGroup size="lg" className="mb-2" vertical>
                  <Button className="dashboardLogoutButton" onClick={handleStartShift}>Start shift</Button>
                  <Button className="dashboardLogoutButton" onClick={handleStopShift}>End shift</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Col>
          <div className="col-md-2" /> {/* Add space */}
          <Col md={5} className="mx-auto d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px', border: '1px solid #00A3E0', borderRadius: '20px', backgroundColor: '#DDF2FA', height: '50vh', color: '#00A3E0' }}>
            <h3>Technicians online:</h3><br /><br />
            <div className="text-center mb-4">
              {onlineTechnician.map((technician, index) => {
                const { name, flag } = mapCountryCodeToInfo(technician.country_code);
                return (
                  <div key={index} className="text-center">
                    <div className="d-flex align-items-center">
                      <div className="blink-green-ring" style={{ width: '15px', height: '15px', borderRadius: '50%', marginRight: '5px' }} />
                      <p style={{ margin: '0' }}><h5>{technician.displayname} from {flag && <img src={flag} alt={name} style={{ width: '20px', marginRight: '1px' }} />} {name} {technician.alarms_type} is now on duty</h5></p>
                    </div>
                    <hr className="w-100" />
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '50px' }}>
          <Col>
            <Paper elevation={3} style={{ marginTop: '2px', marginBottom: '50px', backgroundColor: '#DDF2FA', borderColor: '#00A3E0', border: '1px solid #00A3E0', borderRadius: '20px', }}>
              <h1 style={{ fontFamily: 'Inter', textAlign: 'center', color: '#00A3E0' }}>Last 20 shifts history</h1>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: '#00A3E0' }}><b>#</b></TableCell>
                    <TableCell style={{ color: '#00A3E0' }}><b>Name</b></TableCell>
                    <TableCell style={{ color: '#00A3E0' }}><b>Shift Start Time</b></TableCell>
                    <TableCell style={{ color: '#00A3E0' }}><b>Shift End Time</b></TableCell>
                    <TableCell style={{ color: '#00A3E0' }}><b>Shift Duration</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeShiftData.map((employee, index) => {
                    const startDate = new Date(employee.shift_start_time);
                    const endDate = new Date(employee.shift_end_time);
                    const formattedStartDate = startDate.toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    });
                    const formattedEndDate = endDate.toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    });
                    const durationMs = endDate - startDate;
                    const durationDays = Math.floor(durationMs / (1000 * 60 * 60 * 24));
                    const durationHours = Math.floor((durationMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const durationMinutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
                    const durationSeconds = Math.floor((durationMs % (1000 * 60)) / 1000);

                    return (
                      <TableRow key={index}>
                        <TableCell style={{ color: '#00A3E0' }}>{index + 1}</TableCell>
                        <TableCell style={{ color: '#00A3E0' }}>{employee.displayname}</TableCell>
                        <TableCell style={{ color: '#00A3E0' }}>{formattedStartDate}</TableCell>
                        <TableCell style={{ color: '#00A3E0' }}>{formattedEndDate}</TableCell>
                        <TableCell style={{ color: '#00A3E0' }}>{`${durationDays} days, ${durationHours} hours, ${durationMinutes} minutes, ${durationSeconds} seconds`}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Col>
        </Row>
      </Container>
      <ChangePasswordModal show={showPasswordModal} handleClose={handleClosePasswordModal} storedUser={storedUser} />
      <AdminConsoleModal show={showAdminConsoleModal} handleClose={handleCloseAdminConsoleModal} />
      <footer style={{ backgroundColor: '#071A2B', color: 'white', textAlign: 'center', padding: '20px 0' }}>
        Developed by Dmitry Novozhilov for <b>FILTER</b>.
      </footer>
    </>
  );
};

export default DashboardLvDk;