import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import loginLogo from '../img/logo_white.png';
import ChangePasswordModal from "./ChangePasswordModal";
import AdminConsoleModal from "./AdminConsoleModal"; // Import the AdminConsoleModal component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faSignOutAlt, faCog, faUserPlus } from '@fortawesome/free-solid-svg-icons';

const Navigation = ({ storedUser }) => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState('');
  const [showChangePasswordModal, setChangePasswordModal] = useState(false);
  const [showAdminConsoleModal, setAdminConsoleModal] = useState(false); // State to control the Admin Console modal visibility
  const [userRights, setUserRights] = useState('user');

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActivePage(currentPath);

    const fetchUserRights = async () => {
      try {
        const response = await fetch('https://alarms.filter.eu/php/get_admin_rights.php?username=' + storedUser);
        if (!response.ok) {
          throw new Error('Failed to fetch user rights');
        }
        const data = await response.json();
        setUserRights(data.userRights);
      } catch (error) {
        console.error('Error fetching user rights:', error.message);
      }
    };

    fetchUserRights();
  }, [storedUser]);

  const handleProfileMenuClick = (action) => {
    if (action === "change_password") {
      setChangePasswordModal(true);
    } else if (action === "logout") {
      logoutSubmit();
    }
  };

  const logoutSubmit = () => {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  };

  const openAdminConsoleModal = () => {
    setAdminConsoleModal(true); // Set the state to true to show the Admin Console modal
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbarBg">
        <div className="container">
          <a className="navbar-brand" href="/"><img src={loginLogo} alt="Login" style={{ width: '20vh' }} /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className={`nav-item ${activePage === '/dashboard-ee' ? 'active' : ''}`}>
                <a className="nav-link" href="/dashboard-ee">Estonia</a>
              </li>
              <li className={`nav-item ${activePage === '/dashboard-lv-dk' ? 'active' : ''}`}>
                <a className="nav-link" href="/dashboard-lv-dk">Latvia DK</a>
              </li>
              <li className={`nav-item ${activePage === '/dashboard-lv-dj' ? 'active' : ''}`}>
                <a className="nav-link" href="/dashboard-lv-dj">Latvia DJ</a>
              </li>
            </ul>
            <div className="d-flex">
              <div className="dropdown">
                <button className="btn dropdown-toggle navigationLogoutButton" type="button" id="profileDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  <FontAwesomeIcon icon={faUser} style={{ marginRight: '0.5rem' }} />
                  Profile
                </button>
                <ul className="dropdown-menu" aria-labelledby="profileDropdownMenuButton">
                  {userRights === 'admin' && (
                    <li>
                      <button className="dropdown-item" onClick={openAdminConsoleModal}>
                        <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '0.5rem' }} />
                        Add employee
                      </button>
                    </li>
                  )}                  {userRights === 'admin' && (
                    <li>
                      <a href="/admin" className="dropdown-item">
                        <FontAwesomeIcon icon={faCog} style={{ marginRight: '0.5rem' }} />
                        Admin dashboard
                      </a>
                    </li>
                  )}
                  <li><button className="dropdown-item" onClick={() => handleProfileMenuClick('change_password')}><FontAwesomeIcon icon={faLock} style={{ marginRight: '0.5rem' }} />Change Password</button></li>
                  <li><button className="dropdown-item" onClick={() => handleProfileMenuClick('logout')}><FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '0.5rem' }} />Logout</button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <ChangePasswordModal show={showChangePasswordModal} handleClose={() => setChangePasswordModal(false)} />
      <AdminConsoleModal show={showAdminConsoleModal} handleClose={() => setAdminConsoleModal(false)} /> {/* Render the AdminConsoleModal component */}
    </>
  );
}

export default Navigation;