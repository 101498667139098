import React from 'react';
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';
import loginLogo from '../img/logo_white.png';
import { FaHome, FaNewspaper, FaFileAlt, FaChartBar, FaCalendar, FaCog } from 'react-icons/fa';


const AdminDashboard = () => {
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col sm={2} style={{ backgroundColor: '#071A2B', color: 'white', minHeight: '100vh', padding: '20px 0' }}>
                        <div style={{ marginBottom: '20px', padding: '0 20px' }}>
                            <Navbar.Brand href="#home"><img src={loginLogo} alt="Login" style={{ width: '20vh' }} /></Navbar.Brand>
                        </div>
                        <div style={{ paddingLeft: '20px' }}>
                            <Nav defaultActiveKey="/dashboard" className="flex-column">
                                <Nav.Link href="/" style={{ color: 'white' }}>
                                    <FaHome style={{ marginRight: '5px' }} /> Dashboard
                                </Nav.Link>
                                <Nav.Link href="#news" style={{ color: 'white' }}>
                                    <FaNewspaper style={{ marginRight: '5px' }} /> News
                                </Nav.Link>
                                <Nav.Link href="#pages" style={{ color: 'white' }}>
                                    <FaFileAlt style={{ marginRight: '5px' }} /> Pages
                                </Nav.Link>
                                <Nav.Link href="#graph-charts" style={{ color: 'white' }}>
                                    <FaChartBar style={{ marginRight: '5px' }} /> Graph & Charts
                                </Nav.Link>
                                <Nav.Link href="#events" style={{ color: 'white' }}>
                                    <FaCalendar style={{ marginRight: '5px' }} /> Events
                                </Nav.Link>
                                <Nav.Link href="#admin-tools" style={{ color: 'white' }}>
                                    <FaCog style={{ marginRight: '5px' }} /> Admin Tools
                                </Nav.Link>
                            </Nav>
                        </div>
                    </Col>
                    <Col sm={10}>
                        <div style={{ padding: '20px' }}>
                            <div style={{ border: '1px solid #e8e8e8', marginBottom: '10px' }}>
                                <div style={{ borderBottom: '1px solid #e8e8e8', backgroundColor: '#f3f3f3', padding: '8px', fontSize: '13px', fontWeight: '700', color: '#45484d' }}>
                                    Admin Menu Content
                                </div>
                                <div style={{ padding: '8px', fontSize: '13px' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </div>
                            </div>
                            <div style={{ border: '1px solid #e8e8e8', marginBottom: '10px' }}>
                                <div style={{ borderBottom: '1px solid #e8e8e8', backgroundColor: '#f3f3f3', padding: '8px', fontSize: '13px', fontWeight: '700', color: '#45484d' }}>
                                    Admin Menu Content
                                </div>
                                <div style={{ padding: '8px', fontSize: '13px' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </div>
                            </div>
                            <div style={{ border: '1px solid #e8e8e8', marginBottom: '10px' }}>
                                <div style={{ borderBottom: '1px solid #e8e8e8', backgroundColor: '#f3f3f3', padding: '8px', fontSize: '13px', fontWeight: '700', color: '#45484d' }}>
                                    Admin Menu Content
                                </div>
                                <div style={{ padding: '8px', fontSize: '13px' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default AdminDashboard;