import React, { useState } from 'react';
import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const ChangePasswordModal = ({ show, handleClose, storedUser }) => {
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleRepeatPasswordVisibility = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };

    const handleResetPassword = async () => {
        try {
            if (newPassword !== repeatPassword) {
                setErrorMessage('Passwords do not match');
                return;
            }

            const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()-_=+[\]{};:'",.<>?~\\/]{8,20}$/;
            if (!passwordRegex.test(newPassword)) {
                setErrorMessage('Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces or emoji.');
                return;
            }

            const response = await fetch('https://alarms.filter.eu/php/set_new_password.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: storedUser,
                    new_password: newPassword,
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to reset password');
            }

            console.log('Reset password success:', data);
            handleClose();
        } catch (error) {
            console.error('Error resetting password:', error.message);
            setErrorMessage(error.message || 'An error occurred while resetting the password.');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                <Form.Label htmlFor="new_password">New password</Form.Label>
                <InputGroup>
                    <Form.Control
                        type={showNewPassword ? 'text' : 'password'}
                        id="new_password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <Button variant="outline-secondary" onClick={toggleNewPasswordVisibility}>
                        {showNewPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                    </Button>
                </InputGroup>
                <Form.Label htmlFor="repeat_password">Repeat password</Form.Label>
                <InputGroup>
                    <Form.Control
                        type={showRepeatPassword ? 'text' : 'password'}
                        id="repeat_password"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    <Button variant="outline-secondary" onClick={toggleRepeatPasswordVisibility}>
                        {showRepeatPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                    </Button>
                </InputGroup>
                <Form.Text muted>
                    Your password must be 8-20 characters long, contain letters and numbers,
                    and must not contain spaces or emoji.
                </Form.Text><br /><br />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary navigationLogoutButton" onClick={handleResetPassword}>Reset</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePasswordModal;