import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import loginLogo from '../img/filter_logo_1.png';
import loginBg from '../img/login_bg_right.jpg';
import { Select, MenuItem, InputLabel, TextField } from '@mui/material';
import estoniaFlag from '../img/estonia-flag.png';
import latviaFlag from '../img/latvia-flag.png';
import '../css/dashboard.css'
import PasswordResetModal from "./PasswordResetModal";

const Login = () => {
    const naviget = useNavigate();
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState("");
    const [msg, setMsg] = useState("");
    const [selectedCountry, setSelectedCountry] = useState('Estonia');
    const [showModal, setShowModal] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const screenWidth = window.innerWidth;

    const handleResetPassword = (e) => {
        e.preventDefault();
        // Your password reset logic goes here
        // If reset password logic is successful, set success message and close modal
        setSuccessMsg("Password reset instructions sent to your email.");
        setTimeout(() => {
            setShowModal(false);
            naviget("/"); // Navigate back to login page after a certain delay
        }, 3000);
    };

    useEffect(() => {
        let login = localStorage.getItem("login");
        if (login) {
            switch (selectedCountry) {
                case 'Estonia':
                    naviget("/dashboard-ee");
                    console.log('We go to Estonia');
                    break;
                case 'Latvia-DK':
                    naviget("/dashboard-lv-dk");
                    break;
                case 'Latvia-DJ':
                    naviget("/dashboard-lv-dj");
                    break;
                default:
                    naviget("/dashboard");
            }
        }

        let loginStatus = localStorage.getItem("loginStatus");
        if (loginStatus) {
            setError(loginStatus);
            setTimeout(function () {
                localStorage.clear();
                window.location.reload();
            }, 3000);
        }
        setTimeout(function () {
            setMsg("");
        }, 5000);
    }, [selectedCountry, msg, naviget]);

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleInputChange = (e, type) => {
        switch (type) {
            case "user":
                setError("");
                setUser(e.target.value);
                if (e.target.value === "") {
                    setError("Username has left blank");
                }
                break;
            case "pass":
                setError("");
                setPass(e.target.value);
                if (e.target.value === "") {
                    setError("Password has left blank");
                }
                break;
            default:
        }
    }

    function loginSubmit() {
        if (user !== "" && pass !== "") {

            let url = "https://alarms.filter.eu/php/get_login_credentials.php";
            let headers = {
                "Accept": "application/json",
                "Content-type": "application/json"
            };
            let Data = {
                user: user,
                pass: pass
            };
            fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(Data)
            }).then((response) => response.json())
                .then((response) => {
                    console.log(response);
                    if (response[0].result === "Invalid username!" || response[0].result === "Invalid password!") {
                        setError(response[0].result);
                    }
                    else {
                        setMsg(response[0].result);
                        setTimeout(function () {
                            localStorage.setItem("login", true);
                            localStorage.setItem('user', user);
                            switch (selectedCountry) {
                                case 'Estonia':
                                    naviget("/dashboard-ee");
                                    break;
                                case 'Latvia-DK':
                                    naviget("/dashboard-lv-dk");
                                    break;
                                case 'Latvia-DJ':
                                    naviget("/dashboard-lv-dj");
                                    break;
                                default:
                                    naviget("/dashboard");
                            }
                        }, 1000);
                    }
                }).catch((err) => {
                    setError(err);
                    console.log(err);
                })
        }
        else {
            setError("All field are required!")
        }
    }
    return (
        <>
            <section className="vh-100" style={{ backgroundColor: '#fff', display: 'flex', alignItems: 'center' }}>
                <div style={{
                    flex: '1',
                    background: `url(${loginBg}) center / cover`,
                    height: '100vh',
                    display: screenWidth >= 768 ? 'block' : 'none'
                }} />
                <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="container-fluid py-5" style={{ backgroundColor: '#ffffff', borderRadius: '1rem', overflow: 'hidden' }}>
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                <div className="card-body p-4 p-lg-5 text-black">

                                    <div className="d-flex align-items-center mb-3 pb-1">
                                        <i className="fas fa-cubes fa-2x me-3" style={{ color: '#ff6219' }} />
                                        <span className="h1 fw-bold mb-0"><img src={loginLogo} alt="login form" style={{ width: '100%' }}></img></span>
                                    </div>
                                    <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>Sign into <b>FILTER</b> alarms system</h5>
                                    
                                        {
                                            error !== "" ?
                                                <div class="alert alert-danger"><b>{error}</b></div> :
                                                <div style={{ color: '#badbcc' }}><b>{msg}</b></div>
                                        }
                                    
                                    <div className="form-outline mb-4">
                                    <InputLabel id="username-label">Username</InputLabel>
                                        <TextField
                                            type="text"
                                            id="username"
                                            className="form-control form-control-lg"
                                            value={user}
                                            onChange={(e) => handleInputChange(e, "user")}
                                        />
                                    </div>
                                    <div className="form-outline mb-4">
                                    <InputLabel id="password-label">Password</InputLabel>
                                        <TextField
                                            type="password"
                                            id="pass"
                                            className="form-control form-control-lg"
                                            value={pass}
                                            onChange={(e) => handleInputChange(e, "pass")}
                                        />
                                    </div>
                                    <div className="form-outline mb-4">
                                        <InputLabel id="country-label">Country</InputLabel>
                                        <Select
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                            fullWidth
                                            label="Country"
                                        >
                                            <MenuItem value="Estonia">
                                                <img src={estoniaFlag} alt="Estonia Flag" style={{ marginRight: 10, height: 20 }} />
                                                Estonia
                                            </MenuItem>
                                            <MenuItem value="Latvia-DK">
                                                <img src={latviaFlag} alt="Latvia Flag" style={{ marginRight: 10, height: 20 }} />
                                                Latvia DK
                                            </MenuItem>
                                            <MenuItem value="Latvia-DJ">
                                                <img src={latviaFlag} alt="Latvia Flag" style={{ marginRight: 10, height: 20 }} />
                                                Latvia DJ
                                            </MenuItem>
                                        </Select>
                                    </div>
                                    <div className="d-grid gap-2 mb-4">
                                        <input
                                            type="submit"
                                            value="Login"
                                            className="btn logoutButton btn-lg"
                                            onClick={loginSubmit}
                                        />
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button className="btn logoutButton btn-lg" onClick={() => setShowModal(true)}>Forgot password ?</button>
                                    </div>
                                    <PasswordResetModal
                                        show={showModal}
                                        handleClose={() => setShowModal(false)}
                                        handleResetPassword={handleResetPassword}
                                        error={error}
                                        successMsg={successMsg}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>

    )
}

export default Login;