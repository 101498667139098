import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Protected from "./components/Protected";
import Dashboard from "./components/Dashboard";
import DashboardEe from "./components/DashboardEe";
import DashboardLvDj from "./components/DashboardLvDj";
import DashboardLvDk from "./components/DashboardLvDk";
import Login from "./components/Login";
import AdminDashboard from "./components/AdminDashboard";
 
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
        <Route path="/dashboard-ee" element={<Protected Component={DashboardEe} />} />
        <Route path="/dashboard-lv-dj" element={<Protected Component={DashboardLvDj} />} />
        <Route path="/dashboard-lv-dk" element={<Protected Component={DashboardLvDk} />} />
        <Route path="/admin" element={<Protected Component={AdminDashboard} />} />
      </Routes>
    </BrowserRouter>
  );
}
 
export default App