import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AdminConsoleModal = ({ show, handleClose }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userRole, setUserRole] = useState('');
  const [action, setAction] = useState('');

  const handleSave = () => {
    // Here you can perform the action based on the selected action (edit, remove, or add)
    // For simplicity, I'm just logging the details here
    console.log('Action:', action);
    console.log('Username:', username);
    console.log('Password:', password);
    console.log('User Role:', userRole);

    // After handling the action, you can close the modal
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Admin Console</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formUserRole">
            <Form.Label>User Role</Form.Label>
            <Form.Control
              as="select"
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
            >
              <option value="">Select user role</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formAction">
            <Form.Label>Action</Form.Label>
            <Form.Control
              as="select"
              value={action}
              onChange={(e) => setAction(e.target.value)}
            >
              <option value="">Select action</option>
              <option value="edit">Edit User</option>
              <option value="remove">Remove User</option>
              <option value="add">Add User</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdminConsoleModal;